import {
  Box,
  Divider,
  Flex,
  FlexProps,
  Image,
  Spacer,
  Stack,
  useColorModeValue as mode,
} from "@chakra-ui/react"
import * as React from "react"
import {
  FaAdjust,
  FaPlus,
  FaRegBell,
  FaRegChartBar,
  FaRegClipboard,
  FaRegHeart,
  FaRegImages,
  FaRegQuestionCircle,
  FaSignOutAlt,
} from "react-icons/fa"
import { GoSettings } from "react-icons/go"
import { VscFile, VscFileCode, VscSettingsGear } from "react-icons/vsc"
import { Logo } from "./Logo"
import { NavLink } from "./NavLink"
import { SearchField } from "./SearchField"
import { UserProfile } from "./UserProfile"
import vomuxIcon from "../../images/largeIcon.png"

export const Sidebar = (props: FlexProps) => {
  return (
    <Flex
      bg={mode("gray.50", "gray.800")}
      direction="column"
      borderRightWidth="1px"
      width="64"
      {...props}
    >
      <Flex direction="column" flex="1" pt="5" pb="4" overflowY="auto" px="4">
        <Box>
          <Image src={vomuxIcon} ml="0.25vw" h="6vh" w="6vh" />
          {/* <Logo color={mode('brand.600', 'brand.400')} h="6" /> */}
        </Box>

        <Box mb="6">
          <SearchField />
        </Box>

        <Stack spacing="6" as="nav" aria-label="Sidebar Navigation">
          <Stack spacing="1">
            <NavLink label="Interviews" icon={VscFileCode} isActive />
            <NavLink label="Create Interview" icon={FaPlus} />
          </Stack>

          <Divider />

          <Stack spacing="1">
            {/* <NavLink label="Notifications" icon={FaRegBell} /> */}
            <NavLink label="Settings" icon={VscSettingsGear} />
            <NavLink label="Log Out" icon={FaSignOutAlt} />
          </Stack>
        </Stack>
        <Spacer />
      </Flex>

      <UserProfile
        name="Chase Ward"
        image="https://images.unsplash.com/photo-1521296797187-726205347ca9?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NjR8fGxhZHklMjBzbWlsaW5nfGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
        email="chase@vomux.com"
      />
    </Flex>
  )
}
